export const LOGOUT = "LOGOUT";
export const SET_ACCOUNTS = "SET_ACCOUNTS";
export const SET_ACTIVE_ACCOUNT = "SET_ACTIVE_ACCOUNT";
export const SET_IS_AUTHENTICATED = "SET_IS_AUTHENTICATED";
export const SET_IS_EXPIRED = "SET_IS_EXPIRED";

export const logout = () => ({
    type: LOGOUT,
});

export const setAccounts = (accounts) => ({
    type: SET_ACCOUNTS,
    payload: accounts,
});

export const setActiveAccount = (account) => ({
    type: SET_ACTIVE_ACCOUNT,
    payload: account,
});

export const setIsAuthenticated = (isAuthenticated) => ({
    type: SET_IS_AUTHENTICATED,
    payload: isAuthenticated,
});

export const setIsExpired = (isExpired) => ({
    type: SET_IS_EXPIRED,
    payload: isExpired,
});