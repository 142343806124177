import i18n from 'i18next';
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(HttpBackend)
    .use(LanguageDetector)
    .init({
        debug: false,
        fallbackLng: 'en',
        defaultNS: ['welcome'],
        keySeparator: false,
        supportedLngs: ['en', 'es', 'fr', 'it', 'pl', 'pt', 'ru', 'th', 'vi', 'tr', 'zh-CN', 'zh-TW', 'bn', 'ar', 'ko', 'de', 'si'],
        detection: {
            order: ['localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
        interpolation: {
            escapeValue: false,
        }
    });

export default i18n;