import {getQueryParams, isUrlOauth} from "../services/urlQueryParams";

export const OAuth = async (auth) => {
    if(auth && Object.keys(auth)?.length > 1 && isUrlOauth()){
        history.pushState(null,null, '/');
    }else {
        if(auth && Object.keys(auth)?.length && !isUrlOauth()){
            console.log(auth)
        }else {
            if(isUrlOauth()){
                await getQueryParams();
                history.pushState(null,null, '/');
            }else {
                console.log("fazer novo login");
            }
        }
    }

}