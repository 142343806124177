import {Api} from './live-api';

const api = Api();
import {store} from '../store';
import {setActiveAccount, setAccounts} from '../actions/authActions';

export const getBalance = async (token) => {
    await api.unsubscribeFromBalance();
    await api.authorize(token)
    await api.subscribeToBalance();
    api.events.on('balance', data => {
        const state = store.getState();
        const activeAccount = state.auth.activeAccount;
        const accounts = state.auth.accounts;

        if (activeAccount) {
            const updatedAccount = {
                ...activeAccount,
                balance: data.balance?.balance,
            };
            store.dispatch(setActiveAccount(updatedAccount));
            if (accounts) {
                const updatedAccounts = Object.values(accounts).map((account) =>
                    account.loginid === activeAccount.loginid
                        ? {...account, balance: data.balance?.balance}
                        : account
                );
                store.dispatch(setAccounts(updatedAccounts));
            }
        }
    });
}