import {LiveApi} from 'binary-live-api';
import i18n from './i18n';
import {APP_ID} from "../config";

export const Api = () => {
    return new LiveApi({
        apiUrl: `wss://ws.derivws.com/websockets/v3`,
        language: i18n.language?.toUpperCase(),
        appId: APP_ID,
    });
}