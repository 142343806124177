import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
const PageIndex = () => {
    const {i18n, t} = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

    return (
        <div>
            <h1>teste</h1>
        </div>
    );
};

export default PageIndex;
