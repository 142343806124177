import {Api} from './live-api';
const api = Api();
import { store } from '../store';
import {setAccounts, setActiveAccount, setIsAuthenticated} from '../actions/authActions';
import i18n from "./i18n";
import hash from "../helpers/crypto";
export const getQueryParams = async () => {
    const queryObject = {};
    const searchParams = new URLSearchParams(window.location.search);
    const keys = [...searchParams.keys()];
    if (keys.length > 1) {
        keys.forEach(key => {
            const match = key.match(/([a-z]+)(\d+)/);
            if (match) {
                const [, prefix, index] = match;
                if (!queryObject[index]) {
                    queryObject[index] = {};
                }
                queryObject[index][prefix] = searchParams.get(key);
            }
        });
        const values = Object.values(queryObject);
        let realAccont = null;
        values.forEach(value => {
            if (['USD', 'GBP', 'EUR'].includes(value.cur) && !value.acct.startsWith("VRT")) {
                realAccont = value;
            }
        })
        if(realAccont){
            const accounts = {};

            for (const value of values) {

                const {authorize} = await api.authorize(value.token);
                const {account_list} = authorize;
                for (const account of account_list) {
                    if (value?.acct === account?.loginid) {
                        const dataAccount = {
                            loginid: authorize.loginid,
                            fullname: authorize.fullname,
                            account_category: account.account_category,
                            account_type: account.account_type,
                            account_name: `${(i18n.t(hash("sha256","{%} Account"))).replace("{%}",authorize.currency?.toUpperCase())}`,
                            broker: account.broker,
                            created_at: account.created_at,
                            currency: authorize.currency,
                            currency_type: account.currency_type,
                            is_disabled: account.is_disabled,
                            is_virtual: authorize.is_virtual,
                            landing_company_shortcode: account.landing_company_name,
                            token: value.token,
                            email: authorize.email,
                            landing_company_name: authorize.landing_company_name,
                            balance: authorize.balance,
                            residence: authorize.country
                        }
                        accounts[value?.acct] = dataAccount;
                    }
                }
            }
            store.dispatch(setAccounts(accounts));
            Object.values(accounts).forEach( account => {
                if(realAccont.acct === account.loginid){
                    store.dispatch(setActiveAccount(account));
                    store.dispatch(setIsAuthenticated(true));
                }
            });
        }
    }
}

export const isUrlOauth =  () => {
    const queryUrl = window.location.search;

    if(queryUrl && queryUrl.length > 0){
        return true;
    }
    return false;
}