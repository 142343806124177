import React from "react";
import {Col, NavDropdown, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {setActiveAccount, logout} from "../../../actions/authActions";
import {getBalance} from "../../../services/getBalance";
import {useNavigate} from "react-router-dom";

const NavDropdownUser = () => {
    const dispatch = useDispatch();
    const accounts = useSelector((state) => state.auth.accounts);
    const activeAccount = useSelector((state) => state.auth.activeAccount);
    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(logout());
    };
    const handleAccountChange = (account) => {
        getBalance(account.token)
        dispatch(setActiveAccount(account));
    };

    return (
        <NavDropdown
            className={"px-lg-1"}
            align={"end"}
            title={
                <OverlayTrigger placement={"auto"} overlay={<Tooltip>Dados do Usuário</Tooltip>}>
                    <span>
                        <small className="fw-bold">{activeAccount.account_name}</small><br />
                        <small>{`${activeAccount.loginid} - ${activeAccount.currency} ${activeAccount.balance}`}</small>
                    </span>
                </OverlayTrigger>
            }
        >
            {Object.values(accounts).map((account) => (
                <NavDropdown.Item
                    key={account.loginid}
                    onClick={() => handleAccountChange(account)}
                    active={activeAccount?.loginid === account.loginid}
                >
                    {activeAccount?.loginid === account.loginid ?
                        <span>
                            <small className="fw-bold">{account.account_name}</small><br/>
                            <small>{account.loginid}</small><br/>
                            <small>{`${account.currency} ${account.balance}`}</small>
                        </span>
                        :
                        <span>
                            <small className="fw-bold">{account.account_name}</small><br/>
                            <small>{account.loginid}</small>
                        </span>
                    }

                </NavDropdown.Item>
            ))}
            <NavDropdown.Divider/>
            <NavDropdown.Item className={"fs-6"} onClick={handleLogout}>
                <i className={"me-2 text-primary bi bi-box-arrow-left"}></i> Sair
            </NavDropdown.Item>
        </NavDropdown>
    );
};

export default NavDropdownUser;
