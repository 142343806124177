import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./fonts/robotaur.ttf"
import "./fonts.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
import {Provider} from "react-redux";
import i18n from './services/i18n';
import {store} from "./store";
import init from "./services/init";
import {createStore} from "redux";
import rootReducer from "./reducers";

const persistState = JSON.parse(localStorage.getItem("persist:root")) || {};
delete persistState?._persist;
const storeStart = createStore(rootReducer,persistState);

init(storeStart.getState());

const root = ReactDOM.createRoot(
  document.getElementById('root')
);

root.render(
  <React.StrictMode>
      <Provider store={store}>
          <I18nextProvider i18n={i18n}>
              <App />
          </I18nextProvider>
      </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
