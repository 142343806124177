import { SET_ACCOUNTS, SET_ACTIVE_ACCOUNT, SET_IS_AUTHENTICATED, SET_IS_EXPIRED, LOGOUT } from "../actions/authActions";

const initialState = {
    user: null,
    accounts: [],
    activeAccount: null,
    isAuthenticated: false,
    isExpired: false,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACCOUNTS:
            return { ...state, accounts: action.payload };

        case SET_ACTIVE_ACCOUNT:
            return { ...state, activeAccount: action.payload };

        case SET_IS_AUTHENTICATED:
            return { ...state, isAuthenticated: action.payload };

        case SET_IS_EXPIRED:
            return { ...state, isExpired: action.payload };

        case LOGOUT:
            return { ...state, accounts: [], activeAccount: null, isAuthenticated: false, isExpired: false };

        default:
            return state;
    }
};

export default authReducer;