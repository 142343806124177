import React, {useEffect} from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import NavBar from "./pages/components/navBar";
import FooterCopyright from "./pages/components/footerCopyright";
import Index from "./pages";

function App() {
    return (
        <Router>
            <NavBar/>
            <Routes>
                <Route path={"/"} element={<Index/>}/>
            </Routes>
            <FooterCopyright/>
        </Router>
    );
}

export default App;
